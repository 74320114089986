import React from 'react';
import AliceCarousel from 'react-alice-carousel';

export default () => {

    const handleOnDragStart = e => e.preventDefault();

    const getTestimonials = () => [
        <div className="testimonial" onDragStart={handleOnDragStart}>
            <p className="content">After completing a four-year basketball scholarship at Duke University, winning two ACC championships and going to the Final Four twice, I would strongly recommend the college experience to anyone. Not only did I have the opportunity to play at one of the top ranked college basketball programs in the United States, I also received a free education and a bachelor's degree in science from one of the top academic institutions in America. I am currently in camp with the Connecticut Sun of the WNBA and I owe much of my basketball development to my time playing NCAA women's basketball. I have known Sam Tomlinson for many years and she has always given me great advice throughout my college career and is very knowledgable on the college application and recruiting process. I would recommend seeking the advice of someone like Sam before looking into college as she understands the system well and knows how to find a good fit for players of all different skill levels. Sam is well connected with the college basketball world in the States and her focus and enthusiasm can effectively open up doors for anyone that is serious about playing on an athletic scholarship in America.</p>
            <div className="author">
                <h5>Jess Foley</h5>
                <p>Currently playing for Adelaide Lightning (WNBL) Former player at Duke University</p>
            </div>
        </div>,
        <div className="testimonial" onDragStart={handleOnDragStart}>
            <p className="content">Samantha Tomlinson is your connection for quality student athletes that will fill the needs of your program. Samantha will deal in a direct and concise process with you and your staff to evaluate and recommend players. Samantha has taken her experiences as a outstanding college player and assistant coach in the United States and implemented her principles in discovering talented players and developing an interest in the Australian player to play in the United States. Samantha has spent her entire life in a basketball enviornment. Her father Ray has instructed the top women players in the Australian National programs and her two sisters and mother have excelled in basketball. Sam's work ethic, ability to communicate with the elite players and her teaching insights to develop and improve the player is a service that you must invest with.</p>
            <div className="author">
                <h5>Joe Ciampi</h5>
                <p>Women's Basketball Hall of Fame Former Auburn Unniversity and Army Head Coach 27 yrs.</p>
                <p>Record 607 - 213, 3 Final Fours, 16 NCAA appearences and 8 Southeastern Conference Championships</p>
            </div>
        </div>
    ];

    return (
        <section id="testimonials" className="wrapper testimonials">
            <div className="inner">
                <h2>Testimonials</h2>
                <AliceCarousel
                    mouseDragEnabled
                    items={getTestimonials()} />
            </div>
        </section>
    );
}
