import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import '../sass/main.scss';

export default ({ pageTitle, children, showSideBar, showHeader, activeTab }) => {

    const { site: { siteMetadata: { appTitle, footer: { copyright } } } } = useStaticQuery(
        graphql`
			query {
				site {
					siteMetadata {
                        appTitle
                        footer { 
                            copyright
                        }
					}
				}
			}
		`
    );

    return (
        <Fragment>
            <Helmet
                title={`${pageTitle} - ${appTitle}`}
                meta={[
                    {
                        name: 'description',
                        content: 'Aussie Basketball USA is determined to "bridge the gap" between Australia and the U.S.A. when it comes to college sports.',
                    },
                    {
                        name: 'keywords',
                        content: 'basketball, college pathways, usa college, basketball training, melbourne basketball training, after school basketball, training, basketball coach, sam tomlinson, ray tomlinson',
                    },
                    {
                        name: 'viewport',
                        content: 'width=device-width, initial-scale=1, user-scalable=no'
                    },
                    {
                        name: 'charSet',
                        content: 'utf-8'
                    },
                ]}
                link={[
                    { rel: 'stylesheet', type: 'image/ico', href: `assets/css/main.css` },
                ]}
            />
            {showSideBar ? <Sidebar /> : <Fragment />}
            {showHeader ? <Header activeTab={activeTab} /> : <Fragment />}
            {children}
            <Footer copyright={copyright} />
        </Fragment>
    );
}
