import { Link } from "gatsby";
import React from "react";

export default () => {
  return (
    <section id="programs" className="wrapper style2 programs">
      <section>
        <a href="#intro" className="image">
          <img src="images/college.png" alt="" data-position="25% 25%" />
        </a>
        <div className="content">
          <div className="inner">
            <h2>College Pathways</h2>
            <p>
              Aussie Basketball USA is determined to bridge the gap between
              Australia and the USA when it comes to men and women's basketball
              and college pathways.
            </p>
            <p>
              Our mission is to connect the Australian student athlete with a
              once in a lifetime opportunity of attaining an athletic college
              basketball scholarship at the levels of the NCAA and the NJCAA.
            </p>

            <div className="row">
              <div className="col-lg-6">
                <p className="title">
                  I'm a <strong>Coach</strong>
                </p>
                <p>
                  This scouting service has been approved in accordance with NCAA bylaws, policies, and procedures. NCAA Division I basketball coaches are permitted to subscribe.
                </p>
                <p>
                  The TOP 100 female prospects Australia wide, is right here
                  within our database. Our director and family name 'TOMLINSON'
                  is renown in Australian Womens Basketball, our reputation
                  stands for itself.
                </p>
                <Link
                  className="button primary"
                  to={`/programs/player-database`}
                >
                  Learn more
                </Link>
              </div>

              <div className="col-lg-6">
                <p className="title">
                  I'm a <strong>Prospect</strong>
                </p>
                <p>
                  If you are a player interested pursuing the college pathway in
                  the USA we are the trusted source for you! With over 10 years
                  experience in the industry we can assist you in this gruelling
                  but very rewarding process.
                </p>
                <p>
                  Sam Tomlinson &amp; Aussie Basketball USA, will market you and
                  connect you with the best fit for you academically and on the
                  court. Sam get's the job done on both sides of the pond!
                </p>
                <Link
                  className="button primary"
                  to={`/programs/college-pathways`}
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <a href="#intro" className="image">
          <img
            src="images/holiday-camps.jpg"
            alt=""
            data-position="center right"
          />
        </a>
        <div className="content">
          <div className="inner">
            <h2>Our Super Elite &amp; All Level School Holiday Camps</h2>
            <p>
              These camps are specifically for ages 7 to 16 years, both boys and
              girls. It doesn't matter if you are an elite rep player playing on
              Friday nights, an absolute beginner, a domestic player or just
              wanting extra skill work or practice during the school holidays,
              as at the start of camp, you are broken up into age groups and
              then further by skill level.
            </p>
            <p>
              These grouping rules are quite strict as we like to keep the level
              of play at the absolute highest, but inter-state representative
              players who may be in Melbourne for the school holidays are also
              wel-comed to participate in these camps. Camps operate from 10am
              to 3pm daily with morning tea, afternoon and lunch breaks.
            </p>
            <p>
              All camps are conducted by Olympic player and coach, Ray Tomlinson
              and assisting Sam Tomlinson. Other coaches include state and
              nationally recog-nized coaches so you will not be let down in our
              approach, as our expert instruction helps you develop and
              correctly practice on court fundamentals and take them home with
              you.
            </p>
            <ul className="actions">
              <li>
                <Link className="button" to={`/programs/school-holiday-camps`}>
                  Learn more
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </section>
  );
};
