import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const SidebarComponent = ({ logo, full }) => {

    const [isOpen, setOpen] = useState(false);

    return (
        <section id="sidebar" className={isOpen ? 'nav-is-open' : ''}>
            <div className="inner">
                <Img className="logo" fixed={logo} alt="AussieBasketballUSA" />
                <Img className="logo-full" fixed={full} alt="AussieBasketballUSA" />
                <div className="menu-opener" onClick={() => setOpen(!isOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="navigation">
                    <nav>
                        <ul>
                            <li onClick={() => setOpen(false)}><a href="http://app.aussiebasketballusa.com.au/login">Login</a></li>
                            <li onClick={() => setOpen(false)}><a className="scrolly" href="#programs">Programs</a></li>
                            <li onClick={() => setOpen(false)}><a className="scrolly" href="#events">Upcoming events</a></li>
                            <li onClick={() => setOpen(false)}><a className="scrolly" href="#about">About us</a></li>
                            <li onClick={() => setOpen(false)}><a className="scrolly" href="#news">Latest News</a></li>
                            <li onClick={() => setOpen(false)}><a className="scrolly" href="#testimonials">Testimonials</a></li>
                            <li onClick={() => setOpen(false)}><a className="scrolly" href="#partners">Partners</a></li>
                            <li onClick={() => setOpen(false)}><a className="scrolly" href="#contact">Contact us</a></li>
                        </ul>
                    </nav>
                    <ul className="icons">
                        <li>
                            <a href="https://twitter.com/ausEliteGirlsBa" className="fa-twitter">
                                <span className="label">Twitter</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/aussiebasketballusapathways" className="fa-facebook">
                                <span className="label">Facebook</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/aussiebasketballusacamps" className="fa-instagram">
                                <span className="label">Instagram</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default () => (
    <StaticQuery
        query={graphql`
			query {
				logo: file(relativePath: { eq: "logo.png" }) {
					childImageSharp {
						fixed(width: 183, height: 160) {
							...GatsbyImageSharpFixed
						}
					}
                }
                full: file(relativePath: { eq: "logo--full.png" }) {
					childImageSharp {
						fixed(width: 500, height: 200) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		`}
        render={(data) => {
            return (<SidebarComponent
                logo={data.logo.childImageSharp.fixed}
                full={data.full.childImageSharp.fixed}
            />);
        }}
    />
);