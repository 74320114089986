import React from 'react';

export default () => {
    return (
        <section id="partners" className="wrapper partners">
            <div className="inner">
                <h2>Partners</h2>
                <div className="partner-list">
                    <a href="https://pickandroll.com.au/?utm_source=aussiebasketballusa&amp;utm_medium=referral" rel="noopener noreferrer" target="_blank" className="image">
                        <img src="images/partners/the-pick-and-roll.jpg" alt="The Pick and Roll" />
                    </a>
                    <a href="https://www.facebook.com/Coachfos/" rel="noopener noreferrer" target="_blank" className="image">
                        <img src="images/partners/coach-fos.jpg" alt="Coach FOS" />
                    </a>
                </div>
            </div>
        </section>
    );
}
