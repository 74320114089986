import React from 'react';

export default ({ copyright }) => {
    return (
        <footer id="footer" className="wrapper style1-alt">
            <div className="inner">
                <ul className="menu">
                    <li dangerouslySetInnerHTML={{ __html: copyright }}></li>
                </ul>
            </div>
        </footer>
    );
}
