import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const HeaderComponent = ({ activeTab, logo }) => {
    return (
        <header id="header">
            <Link className="logo" to={`/`}>
                <Img fixed={logo} />
            </Link>
            <nav>
                <ul>
                    <li><a href="http://app.aussiebasketballusa.com.au/login">Login</a></li>
                    <li><Link to={`/#programs`} className={activeTab === 'programs' ? `active` : ''}>Programs</Link></li>
                    <li><Link to={`/#events`}>Events</Link></li>
                    <li><Link to={`/#about`} className={activeTab === 'about' ? `active` : ''}>About us</Link></li>
                    <li><Link to={`/#news`} className={activeTab === 'news' ? `active` : ''}>News</Link></li>
                    <li><Link to={`/#contact`}>Contact</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default ({ activeTab }) => (
	<StaticQuery
		query={graphql`
			query {
				logo: file(relativePath: { eq: "logo.png" }) {
					childImageSharp {
						fixed(width: 180) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		`}
		render={(data) => {
            return (<HeaderComponent 
                logo={data.logo.childImageSharp.fixed}
                activeTab={activeTab}
            />);
		}}
	/>
);