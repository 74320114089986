import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import AliceCarousel from 'react-alice-carousel';

export default () => {

    const handleOnDragStart = e => e.preventDefault();

    const responsive = {
        350: { items: 1 },
        700: { items: 2 },
        1050: { items: 3 },
        1760: { items: 4 }
    };

    const getArticles = (data) => data.allMarkdownRemark.edges.map(({ node: { excerpt, frontmatter: { path, date, title } } }) => (
        <div key={path} className="article" onDragStart={handleOnDragStart} >
            <p className="title">{title}</p>
            <p className="published-date">{date}</p>
            <p className="excerpt">{`${excerpt.slice(0,-20)}...`}</p>
            <Link to={path} className="button">Read more</Link>
        </div>
    ));

    return (
        <section id="news" className="wrapper news">
            <div className="inner">
                <h2>Latest news</h2>
                <p>Keep up to date with the latest developments, watch this space!</p>
                <StaticQuery
                    query={graphql`{
                        allMarkdownRemark(filter: {frontmatter: {template: {eq: "article"}}}, sort: {order: DESC, fields: [frontmatter___date]}) {
                            edges {
                                node {
                                    excerpt(pruneLength:270)
                                    frontmatter {
                                        title
                                        date(formatString: "MMMM DD, YYYY")
                                        path
                                    }
                                }
                            }
                        }
                    }`}
                    render={(data) => (
                        <AliceCarousel
                            mouseDragEnabled
                            infinite={false}
                            responsive={responsive}
                            items={getArticles(data)} />
                    )}
                />
            </div>
        </section>
    );
}
