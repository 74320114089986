import React from 'react';

export default ({ program }) => {
    return (
        <section>
            <form name="contact" netlify-honeypot="bot-field" method="POST" data-netlify="true">
                <div className="fields">
                    <p className="hidden">
                        <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                    </p>
                    <div className="field half">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" required />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" required />
                    </div>
                    <div className="field">
                        <label htmlFor="program">Subject</label>
                        <select name="program" id="program" required defaultValue={program}>
                            <option value="college-pathways">College Pathways</option>
                            <option value="school-holiday-camps">School Holiday Camps</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows="5" required />
                    </div>
                </div>
                <ul className="actions">
                    <li>
                        <button className="button submit" type="submit">Submit</button>
                    </li>
                </ul>
            </form>
        </section>
    )
}