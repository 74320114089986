import React from 'react';

export default () => {
    return (
        <section id="intro" className="wrapper style1 fullscreen">
            <div className="intro-container fade-up">
                <img className="logo" src="images/logo--full.png" alt="AussieBasketballUSA Logo" />
                <img className="splash-text" src="images/splash/splash.png" alt="AussieBasketballUSA Splash" />
            </div>
        </section>
    );
}
