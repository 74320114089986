import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const AboutUsComponent = ({ coaches }) => {
  const sam = coaches.find(
    (coach) => coach.node.frontmatter.title === "Sam Tomlinson"
  ).node;
  const ray = coaches.find(
    (coach) => coach.node.frontmatter.title === "Ray Tomlinson"
  ).node;
  const nick = coaches.find(
    (coach) => coach.node.frontmatter.title === "Nick Abdicevic"
  ).node;

  const services = [
    {
      id: "service-1",
      title: "TRUSTED SOURCE",
      text:
        "Players are scouted and coached at our super camps by one of the most renowned names in Australian Basketball.",
    },
    {
      id: "service-2",
      title: "MARKET & CONNECT",
      text:
        "With over 20 years of college connections we can definitely market your game and connect you with the right college fit for you.",
    },
    {
      id: "service-3",
      title: "COST EFFECTIVE",
      text:
        "Although our super camps and access to our extensive database cost, our student athletes are FREE of charge.",
    },
    {
      id: "service-4",
      title: "ACADEMIC ADVISE",
      text:
        "We can help guide the academic process to become eligible for what is to come out of an exciting once in a life time opportunity to play at the USA Collegiate Level.",
    },
  ];

  return (
    <section id="about" className={"wrapper style3 fade-up"}>
      <div className="inner">
        <h2>About us</h2>

        <p>
          Aussie Basketball USA is determined to "bridge the gap" between
          Australia and the shores of the USA when it comes to NCAA Collegiate
          Mens and Womens Basketball. Our mission is to connect the Student -
          Athlete with a once in a lifetime opportunity of obtaining a USA
          College Basketball Scholarship and find the right fit. Please read
          further to learn more about our leaders at Aussie Basketball USA.
        </p>
        <p>
          Aussie basketball USA Pathways is undoubtably the number one program
          in assisting boys and girls in realising their dreams of playing NCAA
          Division 1 Womens Basketball in the USA. The brainchild of former NCAA
          Division One Player and Coach Sam Tomlinson, has helped send more than
          enough players, including numerous junior national, state and club
          level players to various Division One Programs including Duke
          University, Tennessee and Clemson among others just of late.
        </p>
        <p>
          Having won Gold with the Australian Junior National Team at the U21
          World Cup before carving out a career both in the Australian WNBL,
          Europe and the WNBA pre team squad. Tomlinson has first- hand
          knowledge of the opportunities that basketball can present for players
          as well as well as what it takes to reach the elite level in the
          sport. Combined with her passion for junior player development,
          Tomlinson is able to give each player the opportunity to put
          themselves in the best possible position to land a high level of play
          at the Division One level and give them the best chance to succeed
          both in the short and long-term.
        </p>
        <p>
          With a wealth of experience in both Australia and the United States,
          Tomlinson has a proven track record of assisting players in finding
          their way to the ideal college setting, creating mutually beneficial
          relationships for the players, coaches and programs, helping to
          develop long-term connections between programs and Australian
          basketball.
        </p>

        <div className="services-container">
          <h3>Our Services</h3>
          <div className="services">
            {services.map(({ id, title, text, icon }) => (
              <section key={id}>
                <h3>{title}</h3>
                <p>{text}</p>
              </section>
            ))}
          </div>
        </div>
        <div className="directors">
          <h3>Our Leaders</h3>
          <p>
            Director, Sam Tomlinson is a former NCAA Division One and
            professional basketball player, who learnt her trade from one of
            Australia's most well known players and coaches, Ray Tomlinson.
          </p>
          <div className="director sam">
            <div className="avatar">
              <Img
                alt="Sam Tomlinson"
                fixed={sam.frontmatter.image.childImageSharp.fixed}
              />
              <small>Sam Tomlinson</small>
            </div>
            <div className="content">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${sam.excerpt.slice(0, -20)}...`,
                }}
              />
              <Link to={sam.frontmatter.path} className="button">
                Read more
              </Link>
            </div>
          </div>
          <hr />
          <div className="director nick">
            <div className="content">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${nick.excerpt.slice(0, 600)}...`,
                }}
              />
              <Link to={nick.frontmatter.path} className="button">
                Read more
              </Link>
            </div>
            <div className="avatar">
              <Img
                alt="Nick Abdicevic"
                fixed={nick.frontmatter.image.childImageSharp.fixed}
              />
              <small>Nick Abdicevic</small>
            </div>
          </div>
          <hr />
          <div className="director ray">
            <div className="avatar">
              <Img
                alt="Ray Tomlinson"
                fixed={ray.frontmatter.image.childImageSharp.fixed}
              />
              <small>Ray Tomlinson</small>
            </div>
            <div className="content">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${ray.excerpt.slice(0, -20)}...`,
                }}
              />
              <Link to={ray.frontmatter.path} className="button">
                Read more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "coach" } } }
        ) {
          edges {
            node {
              excerpt(format: HTML, pruneLength: 770)
              frontmatter {
                title
                path
                image {
                  childImageSharp {
                    fixed(width: 180, height: 300) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return <AboutUsComponent coaches={data.allMarkdownRemark.edges} />;
    }}
  />
);
