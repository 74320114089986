import React from 'react';
import AliceCarousel from 'react-alice-carousel';

export default () => {

    const handleOnDragStart = e => e.preventDefault();

    const responsive = {
        350: { items: 2 },
        700: { items: 2 },
        1050: { items: 2 },
        1760: { items: 2 },
    };

    const items = [
        <a className="next-event" key={0} href="/programs/school-holiday-camps">
            <img src="images/events/ABUSA_April_1.png" alt="AussieBasketballUSA Event" onDragStart={handleOnDragStart} />
            <p>Next Event</p>
        </a>,
        <a key={1} href="/programs/school-holiday-camps">
            <img src="images/events/ABUSA_April_2.png" alt="AussieBasketballUSA Event" onDragStart={handleOnDragStart} />
        </a>,
    ];

    return (
        <section id="events" className="wrapper events">
            <div className="inner">
                <h2>Upcoming events</h2>
                <p>Stay on top of it by checking out our schedule of events.</p>
                <div style={{ 'maxWidth': 800 }}>
                    <AliceCarousel
                        startIndex={0}
                        mouseDragEnabled
                        infinite={false}
                        responsive={responsive}
                        items={items} />
                </div>
            </div>
        </section>
    );
}
