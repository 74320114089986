import React from 'react';
// import ContactForm from './ContactForm';

export default () => {

    const social = [
        {
            title: 'Twitter',
            icon: 'fa-twitter',
            url: 'https://twitter.com/ausEliteGirlsBa'
        },
        {
            title: 'Facebook',
            icon: 'fa-facebook',
            url: 'https://www.facebook.com/aussiebasketballusapathways'
        },
        {
            title: 'Instagram',
            icon: 'fa-instagram',
            url: 'https://www.instagram.com/aussiebasketballusacamps'
        }
    ];

    return (
        <section id="contact" className={'wrapper style1'}>
            <div className="inner">
                <h2>Get in touch</h2>
                <p>Reach out to us via social media, email or phone call. We'd love to have a chat!</p>
                <div className="__split __style1">
                    {/* <ContactForm /> */}
                    <section>
                        <ul className="contact">
                            <li>
                                <h3>Based</h3>
                                <span>Melbourne, Australia</span>
                            </li>
                            <li>
                                <h3>Email</h3>
                                <a href="mailto:sam@aussiebasketballusa.com.au">sam@aussiebasketballusa.com.au</a>
                            </li>
                            <li>
                                <h3>Phone</h3>
                                <a href="tel:0424194157">0424-194-157</a>
                            </li>
                            <li>
                                <h3>Social</h3>
                                <ul className="icons">
                                    {social.map(({ title, icon, url }) => (
                                        <li key={title}>
                                            <a href={url} className={`${icon}`}>
                                                <span className="label">{title}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </section>
    );
}
